<!--
--------------------------------------------------------------------------------
<copyright file="MachineStatesGantt.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.machine_states_gantt') }}</summary>
      <p class="text">{{ $t('widget_info_text.machine_states_gantt_txt') }}</p>
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
      <p class="text tip-bkg">{{ $t('widget_info_text.bars_tip_txt_cat') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.machine_states_gantt_xaxis') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.machine_states_gantt_yaxis') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.machine_states_gantt_bars') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.fact_overview_status_info') }}</summary>
      <p class="text">{{ $t('widget_info_text.color_code') }}:</p>
      <ul class="list">
        <li class="list-item">
          <span class="work">{{ $t('widget_info_text.green') }}</span>
          {{ $t('widget_info_text.fact_overview_green_txt') }}
        </li>
        <li class="list-item">
          <span class="idle">{{ $t('widget_info_text.light_orange') }}</span>
          {{ $t('widget_info_text.machine_states_gantt_lOrange_txt') }}
        </li>
        <li class="list-item">
          <span class="stop">{{ $t('widget_info_text.dark_orange') }}</span>
          {{ $t('widget_info_text.machine_states_gantt_dOrange_txt') }}
        </li>
        <li class="list-item">
          <span class="wait">{{ $t('widget_info_text.brown') }}</span>
          {{ $t('widget_info_text.fact_overview_brown_txt') }}
        </li>
        <li class="list-item">
          <span class="offline">{{ $t('widget_info_text.dark_gray') }}</span
          >, {{ $t('widget_info_text.fact_overview_dgray_txt') }}
        </li>
        <li class="list-item">
          <span class="error">{{ $t('widget_info_text.red') }}</span
          >, {{ $t('widget_info_text.fact_overview_red_txt') }}
        </li>
      </ul>
      <br />
      <p class="tip list-item tip-bkg">{{ $t('widget_info_text.tip') }}</p>
      <ul class="list tip-bkg">
        <li class="list-item2">{{ $t('widget_info_text.tip_hist_txt') }}</li>
        <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
      </ul>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class MachineStatesGanttWidgetInfo extends Vue {}
</script>
